import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { HeatmapRect } from '@visx/heatmap';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { format } from 'date-fns';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { getLocale } from '@shared/lib/date-time';
import { useAppSelector } from '@app/store/Hooks';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import TooltipStyled from './TooltipStyled';
const MonthContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    display: 'inline-flex',
    flex: '1 1 0',
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.5,
}));
const MonthGroup = styled.svg(props => ({
    width: props.weeksCount * 9 + (props.weeksCount - 1) * 3 + 4,
    height: 85,
}));
const ActivityHeatmapContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
    flexWrap: 'wrap',
}));
const HoverRect = styled.rect(() => ({
    width: 11,
    height: 11,
    fill: 'transparent',
}));
const MonthName = styled.span(() => ({
    lineHeight: '15px',
    fontSize: 12,
    color: '#ABB4BB',
}));
const UnitGroup = styled.g(props => ({
    '&:hover': {
        [`${HoverRect}`]: {
            stroke: props.theme.palette.info.main,
            strokeWidth: 1,
        },
    },
}));
const TradingActivityHeatmap = ({ data, isLoading = false, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { language } = useAppSelector((state) => state.app);
    const defineColorScale = (count) => {
        if (typeof count === 'number') {
            if (count >= 28) {
                return theme.palette.info.main;
            }
            if (count < 17 && count >= 10) {
                return '#96C7F2';
            }
            if (count > 0) {
                return theme.palette.info.light;
            }
            return '#EFF1F3';
        }
        return '#EFF1F3';
    };
    const monthNamesShort = useMemo(() => ({
        1: t('date_time.month.short.january', { ns: 'common' }),
        2: t('date_time.month.short.february', { ns: 'common' }),
        3: t('date_time.month.short.march', { ns: 'common' }),
        4: t('date_time.month.short.april', { ns: 'common' }),
        5: t('date_time.month.short.may', { ns: 'common' }),
        6: t('date_time.month.short.june', { ns: 'common' }),
        7: t('date_time.month.short.july', { ns: 'common' }),
        8: t('date_time.month.short.august', { ns: 'common' }),
        9: t('date_time.month.short.september', { ns: 'common' }),
        10: t('date_time.month.short.october', { ns: 'common' }),
        11: t('date_time.month.short.november', { ns: 'common' }),
        12: t('date_time.month.short.december', { ns: 'common' }),
    }), [t]);
    return (_jsx(ActivityHeatmapContainer, { children: _jsx(WithSkeleton, { isLoading: isLoading, width: '100%', height: 102, children: _jsx(HeatmapRect, { xScale: index => index, yScale: index => index, binWidth: 9, binHeight: 9, colorScale: defineColorScale, rx: 2, data: data, children: (cells) => cells.map((cellMonth, index) => (_jsxs(MonthContainer, { children: [_jsx(MonthGroup, { weeksCount: (cellMonth === null || cellMonth === void 0 ? void 0 : cellMonth.length) ? cellMonth[0].datum.weeksCount : 5, children: cellMonth.map(({ color, bin: { date, dayInWeek, week, count, year, month, }, }) => (_jsx(TooltipStyled, { title: Trans({
                                    i18nKey: 'pages.portfolios.general_portfolio_information.trading_activity.day_activity',
                                    ns: 'common',
                                    values: {
                                        activityCounter: `${count} ${t('plural_variables.closed_position', {
                                            ns: 'common',
                                            count,
                                        })}`,
                                        activityDate: `${format(new Date(year, month, date), 'do MMMM', { locale: getLocale(language || 'en') })}`,
                                    },
                                }), placement: 'top', componentsProps: {
                                    tooltip: { style: { marginBottom: 0 } },
                                    arrow: {
                                        style: {
                                            position: 'absolute',
                                            top: '100%',
                                            margin: 0,
                                            width: 10,
                                            height: 5,
                                            left: 'calc(50% - 5px)',
                                        },
                                    },
                                }, tooltipYOffset: 6, arrow: true, children: _jsxs(UnitGroup, { children: [_jsx(HoverRect, { x: ((week - 1) * 12) + 1, y: ((dayInWeek - 1) * 12) + 1, rx: '2' }), _jsx("rect", { className: 'visx-heatmap-rect', fill: color, fillOpacity: '1', width: 9, height: 9, x: ((week - 1) * 12) + 2, y: ((dayInWeek - 1) * 12) + 2, rx: '2' }, 'day-we')] }, `activity-day-${date}`) }, `activity-day-tooltip-${date}`))) }, `activity-month-group-${(cellMonth === null || cellMonth === void 0 ? void 0 : cellMonth.length) ? cellMonth[0].datum.monthOrder : index}`), _jsx(MonthName, { children: `${(cellMonth[0].datum.monthOrder &&
                                monthNamesShort[cellMonth[0].datum.monthOrder]) || ''}` })] }, `activity-month-container-${(cellMonth === null || cellMonth === void 0 ? void 0 : cellMonth.length) ? cellMonth[0].datum.monthOrder : index}`))) }) }) }));
};
export default TradingActivityHeatmap;
